import { defineStore } from "pinia";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureGraphDataFetcher from "@/services/connectors/azureGraphConnector";

const graph = new AzureGraphDataFetcher();

export const useAzureStore = defineStore("azure", {
  state: () => ({
    groupMembers: [] as { value: any }[],
    userPhoto: {
      blob: {} as any,
      loader: true,
    },
    userManager: {
      data: {} as any,
      loader: true,
    },
  }),
  actions: {
    async loadPhoto() {
      this.userPhoto.loader = true;
      const result = await graph.getMyPhoto();
      this.userPhoto.blob = result;
      this.userPhoto.loader = false;
    },
    async loadUserManager(id: string) {
      try {
        this.userManager.loader = true;
        const result = await graph.getUserManager(id);
        this.userManager.data = result;
      } catch (e) {
        console.error(e);
      } finally {
        this.userManager.loader = false;
      }
    },
  },
  getters: {
    getGroupMembers(): any[] {
      return this.groupMembers;
    },
    getPhoto(): any {
      return this.userPhoto.blob;
    },
    getPhotoLoader(): boolean {
      return this.userPhoto.loader;
    },
    getUserManager(): any {
      return this.userManager.data;
    },
    getUserManagerLoader(): boolean {
      return this.userManager.loader;
    },
  },
});
