import { defineStore } from "pinia";
import { TeamStory } from "@/services/entities/teamStory";
import TeamStoryDataFetcher from "@/services/connectors/teamStoryConnector";
import { getServerUrl } from "@/common/urls";

const teamStoryDataFetcher = new TeamStoryDataFetcher(getServerUrl());

export const useTeamStoryStore = defineStore("teamStory", {
  state: () => ({
    // Define your state properties here
    teamStoryData: [] as TeamStory[],
    template: {} as TeamStory,
    isLoading: false,
    error: null,
  }),

  actions: {
    async loadTeamStory(templateId: string) {
      try {
        this.isLoading = true;
        this.error = null;

        this.teamStoryData = await teamStoryDataFetcher.getTeamStory(templateId);

        this.isLoading = false;
      } catch (error: any) {
        this.isLoading = false;
        this.error = error.message;
      }
    },

    async loadTemplate(templateId: string) {
      try {
        this.isLoading = true;
        this.error = null;

        this.template = await teamStoryDataFetcher.getTemplate(templateId);

        this.isLoading = false;
      } catch (error: any) {
        this.isLoading = false;
        this.error = error.message;
      }
    },

    // Define other actions here
    // For example:
    async updateTeamStory(newTeamStoryData: TeamStory) {
      try {
        this.isLoading = true;
        this.error = null;

        await teamStoryDataFetcher.updateTeamStory(newTeamStoryData);

        this.isLoading = false;
      } catch (error: any) {
        this.isLoading = false;
        this.error = error.message;
      }
    },

    async createTeamStory(templateId: string, newTeamStoryData: TeamStory) {
      try {
        this.isLoading = true;
        this.error = null;

        await teamStoryDataFetcher.createTeamStory(templateId, newTeamStoryData);

        this.isLoading = false;
      } catch (error: any) {
        this.isLoading = false;
        this.error = error.message;
      }
    },
  },
  getters: {
    getTeamStories(): TeamStory[] {
      return this.teamStoryData;
    },
    getLoadingStatus(): boolean {
      return this.isLoading;
    },
    getTemplate(): TeamStory {
      return this.template;
    },
  },
});
