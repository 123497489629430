<template>
    <div class="main">
        <div class="title">
            <h4>{{ teamStore.getTeamName }}</h4>
        </div>
        <div class="row space-between">
            <div>
                <input id="title" type="text" v-model="state.title" placeholder="Choose a Title" />
            </div>
            <div>
                <router-link :to="{ name: 'Home' }">
                    <button class="red-bg bt">Discard</button>
                </router-link>
                <button @click.once="save">Save</button>
            </div>
        </div>
        <div class="text-editor">
            <RichTextBox v-model="state.value" />
        </div>
    </div>
</template>

<script setup lang="ts">
import RichTextBox from "@/components/RichTextBox.vue";
import { TeamStory } from "@/services/entities/teamStory";
import { useTeamStoryStore } from "@/stores/teamStory";
import { onMounted, reactive } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { getQueryParamByName } from "@/common/util";
import router from "@/router";
import { useTeamStore } from "@/stores/team";
import { toast } from "vue3-toastify";

const teamStoryStore = useTeamStoryStore();
const teamStore = useTeamStore();

const props = defineProps<{
    template: string;
}>();

onMounted(async () => {
    await teamStoryStore.loadTemplate(props.template);
    state.title = teamStoryStore.getTemplate?.heading ?? "";
    state.value = teamStoryStore.getTemplate?.body ?? "";
});

const state = reactive({
    value: "",
    title: "",
    saved: false
});

async function save() {
    let story = {
        heading: state.title,
        body: state.value,     
        tags: teamStoryStore.getTemplate.tags,   
    } as any as TeamStory;
    const t = toast.loading("Saving...");

    await teamStoryStore.createTeamStory(props.template, story);

    state.saved = true;
    toast.remove(t);
    toast.success("Saved!");
    setTimeout(() => {
        router.push({ name: 'Home' });
    }, 1000);
}

onBeforeRouteLeave((to, from, next) => {
    if (!state.saved && !confirm('Discard changes?')) {
        next(false);
    } else {
        next();
    }
});

</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: 80%;
}

.text-editor {
    margin-top: 5px;
    width: 100%;
    height: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.space-between {
        justify-content: space-between;
    }
}

.row-right {
    display: flex;
    margin-top: 10px;
    align-items: right;
    justify-content: right;
}

.bt {
    margin-right: 5px;
}
</style>