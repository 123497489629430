<template>
    <div class="item-container">
        <div class="story-item">
            <div class="heading">
                <h3>
                    {{ story.heading }}
                </h3>
                <div class="story-action">
                    <router-link :to="{ name: 'Edit', params: { id: story.id } }">
                        <button class="bt-sm">Edit</button></router-link>
                    <button class="bt-sm" @click="goToDetail">Detail</button>
                </div>
            </div>
            <div class="item-info">
                <span>Created: {{ formatDate(story.created ?? story.modifiedDateStamp) }}</span>
                <span>Modified: {{ formatDate(story.modifiedDateStamp) }}</span>
                <span>By {{ story.employee?.name }}</span>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">
import router from "@/router";
import { TeamStory } from "@/services/entities/teamStory";

const props = defineProps<{
    story: TeamStory;
}>();

const formatDate = (date: Date | undefined | string): string => {
    if (!date) {
        return "";
    }
    else if (typeof date === 'string') {
        date = new Date(date);
    }
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    return date.toLocaleDateString(undefined, options);
};

const goToDetail = () => {
    router.push({ name: 'Detail', params: { id: props.story.id } });
};

</script>

<style lang="scss" scoped>
.story-action {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;

    button{
        background-color: #007bff;
        font-size: 15px;
    }
}

.story-item {
    display: flex;
    flex-direction: column;

    &>span {
        padding: 5px;
        background-color: #fff;
    }

    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        background-color: rgb(255, 255, 255);
        border-radius: 5px 5px 0 0;

        h3 {
            margin: 0;
        }

        a {
            color: black;
        }
    }

    .item-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 5px;
        background-color: #fff;
        border-radius: 0 0 5px 5px;
        flex-wrap: wrap;
        font-size: 12px;
    }
}

.item-container {
    box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.border-bottom-soft {
    border-bottom: 1px solid lightgray;
}

.fa {
    color: #007bff;
}

.bt-sm {
    font-size: 12px;
    padding: 2px;
    padding-left: 3px;
    padding-right: 3px;
    margin-right: 4px;
    border-radius: 5px;
    background-color: lightgray;
    border: 1px solid lightgray;
}
</style>