import "@/common/mockPageContextInfo";
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import { createVfm } from 'vue-final-modal'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';

// import CSS files
import 'vue3-toastify/dist/index.css';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import "devextreme/ui/html_editor/converters/markdown";

import {
  faHome,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faEdit,
  faInfo,
  faQuestionCircle,
  faToriiGate,
  faCircleExclamation,
  faChevronRight,
  faRotateLeft,
  faXmark,
  faGear,
  faThumbsUp,
  faClipboardList,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faHome,
  faInfoCircle,
  faPencilAlt,
  faPlus,
  faEdit,
  faInfo,
  faQuestionCircle,
  faToriiGate,
  faCircleExclamation,
  faChevronRight,
  faRotateLeft,
  faXmark,
  faGear,
  faThumbsUp,
  faClipboardList,
);
const vfm = createVfm();

const app = createApp(App);
app.config.globalProperties.$msalInstance = {};

app.use(createPinia())
  .use(vfm)
  .use(router)
  .use(Vue3Toastify, {
    autoClose: false, position: "top-center", duration: 5000
  } as ToastContainerOptions)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount('#app')
