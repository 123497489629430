<template>
    <div class="loading-background">
    <div class="loading-dots">
        <div class="loading-dots--dot"></div>
        <div class="loading-dots--dot"></div>
        <div class="loading-dots--dot"></div>
    </div>
</div>
</template>

<script setuo>
</script>

<style scoped lang="scss">
@keyframes dot-keyframes {
    0% {
        opacity: .4;
        transform: scale(1, 1);
    }

    50% {
        opacity: 1;
        transform: scale(1.2, 1.2);
    }

    100% {
        opacity: .4;
        transform: scale(1, 1);
    }

}

.loading-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

.loading-dots {
    text-align: center;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--dot {
        animation: dot-keyframes 1.5s infinite ease-in-out;
        background-color: #2c3e50;
        border-radius: 35px;
        display: inline-block;
        height: 35px;
        width: 35px;
        margin-left: 10px;

        &:nth-child(2) {
            animation-delay: .5s;
        }

        &:nth-child(3) {
            animation-delay: 1s;
        }
    }
}
</style>