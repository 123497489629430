import { getServerUrl } from "@/common/urls";
import TeamDataFetcher from "@/services/connectors/teamConnector";
import { TeamInfo } from "@/services/entities/team";
import { defineStore } from "pinia";

const teamStoryDataFetcher = new TeamDataFetcher(getServerUrl());

export const useTeamStore = defineStore("team", {
  state: () => ({
    // Define your state properties here
    team: {} as TeamInfo,
    loader: false,
  }),

  actions: {
    // Define your actions here
    async fetchTeamInfo(target: string) {
      this.loader = true;
      const result = await teamStoryDataFetcher.getTeamInfo(target);
      if (result.length > 0) {
        this.team = result[0];
      } else {
        this.team = {
          teamName: "Unknown",
          teamId: "Unknown",
        } as TeamInfo;
        this.loader = false;
      }
    },
  },

  getters: {
    // Define your getters here
    getTeam(): TeamInfo {
      return this.team;
    },
    getLoader(): boolean {
      return this.loader;
    },
    getTeamName(): string {
      console.log(this.team);
      if (this.team) {
        console.log(this.team.teamName);
        if(this.team.teamName) return this.team.teamName;
        else return this.team.teamId;
      }
      return "Unknown";
    },
  },
});
