import ApiConnector from "../apiConnector";
import { TeamInfo } from "../entities/team";

class TeamDataFetcher {
    private readonly apiConnector: ApiConnector;

    constructor(baseURL: string){
        this.apiConnector = new ApiConnector(baseURL);
    }

    public async getTeamInfo(teamId: string): Promise<TeamInfo[]> {
        return this.apiConnector.get(`/team/${teamId}`);
    }
}

export default TeamDataFetcher;