<template>
    <div class="main">
        <div class="title">
            <h4>{{ teamStore.getTeamName }}</h4>
        </div>
        <div class="row space-between">
            <div>
                <input id="title" type="text" v-model="state.story.heading" placeholder="Choose a Title" />
            </div>
            <div>
                <router-link :to="{ name: 'Home' }">
                    <button class="red-bg bt">Discard</button>
                </router-link>
                <button @click="save">Save</button>
            </div>
        </div>
        <div class="text-editor">
            <RichTextBox v-model="state.story.body" />
        </div>

    </div>
</template>

<script setup lang="ts">
import RichTextBox from "@/components/RichTextBox.vue";
import router from "@/router";
import { TeamStory } from "@/services/entities/teamStory";
import { useTeamStore } from "@/stores/team";
import { useTeamStoryStore } from "@/stores/teamStory";
import { onMounted, reactive } from "vue";
import { onBeforeRouteLeave } from "vue-router";
import { toast } from "vue3-toastify";

const teamStoryStore = useTeamStoryStore();
const teamStore = useTeamStore();

const props = defineProps<{
    id: string;
    template: string;
}>();

const state = reactive({
    saved: false,
    story: { heading: "", body: "" } as any as TeamStory,
});

const oldStory = { heading: "", body: "" };

async function save() {
    const t = toast.loading("Saving...");
    await teamStoryStore.updateTeamStory(state.story);
    state.saved = true;
    toast.remove(t);
    toast.success("Saved!");
    setTimeout(() => {
        router.push({ name: 'Home' });
    }, 1000);
}

onBeforeRouteLeave((to, from, next) => {
    if (!state.saved && !confirm('Discard changes?')) {
        next(false);
    } else {
        next();
    }
});

onMounted(async () => {
    if (props.id) {
        if (teamStoryStore.getTeamStories.length === 0) {
            await teamStoryStore.loadTeamStory(props.template);
        }
        const result = teamStoryStore.getTeamStories.find((story) => story.id === props.id);
        if (result) {
            state.story = result;
            oldStory.heading = result.heading;
            oldStory.body = result.body;
        }
    }
});

</script>

<style lang="scss" scoped>
.main {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    height: 80%;
}

.text-editor {
    margin-top: 5px;
    width: 100%;
    height: 100%;
}

.row {
    display: flex;
    flex-direction: row;
    width: 100%;

    &.space-between {
        justify-content: space-between;
    }
}

.row-right {
    display: flex;
    margin-top: 10px;
    align-items: right;
    justify-content: right;
}

.bt {
    margin-right: 5px;
}
</style>