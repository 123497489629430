/* eslint-disable */ 
import { isDevelopment } from "./env";

if (isDevelopment) {
  window._spPageContextInfo = {
    userDisplayName: "Tomas Jiricek",
    userEmail: "tomas.jiricek@cz.abb.com",
    //userEmail: "miroslav.urbanek@cz.abb.com",
    userId: 18613,
    portalUrl: "https://abb.sharepoint.com/",
    siteAbsoluteUrl: "http://localhost:8080/sites/NO-CompetenceLibrary",
    siteServerRelativeUrl: "/sites/NO-CompetenceLibrary",
    webAbsoluteUrl: "http://localhost:8080/sites/NO-CompetenceLibrary/PA-OGC-KPI",
    webServerRelativeUrl: "/sites/NO-CompetenceLibrary/PA-OGC-KPI",
    webTitle: "OGC-KPI",
  } as _spPageContextInfo;
}
