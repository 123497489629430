import { isDevelopment } from "@/common/env";
import { PublicClientApplication } from "@azure/msal-browser";

const baseURL = process.env.BASE_URL;

if (!baseURL) {
  throw new Error("Base URL not defined in environment variables");
}
const clientId = process.env.VUE_APP_U3T_ADAL_CLIENT_ID as string;

const msalConfig = {
  auth: {
    clientId: clientId,
    authority:
      "https://login.microsoftonline.com/372ee9e0-9ce0-4033-a64a-c07073a91ecd",
  },
  cache: {
    cacheLocation: "sessionStorage",
  },
  system: {
    tokenRenewalOffsetSeconds: 600,
    iframeHashTimeout: 30000, // iframe is used to refresh the refresh_token as it has a 24h lifetime
  },
};

export enum LogStatus {
  None,
  NotAuthorized,
  NoAccess,
  Logged,
}

export class Authorization {
  private static _token: string = "";
  private static _name: string = "Uknown user";
  private static _logStatus: LogStatus = LogStatus.None;
  private static _id: string = "";
  private static _mail: string = "";

  static get token(): string {
    return this._token;
  }

  static set token(value: string) {
    this._token = value;
  }

  static get accountName(): string {
    return this._name;
  }

  static get id(): string {
    return this._id;
  }

  static get logStatus(): LogStatus {
    return this._logStatus;
  }

  static get isLogged(): boolean {
    return this._logStatus == LogStatus.Logged;
  }

  static get mail(): string {
    return this._mail;
  }

  private static msalInstance = new PublicClientApplication(msalConfig);

  static async signIn() {
    if (!isDevelopment) {
      await this.getToken();
    } else {
      this.token = process.env.VUE_APP_ACCESS ?? "";
      this._id = process.env.VUE_APP_USER_ID ?? "";
      this._name = process.env.VUE_APP_USER_NAME ?? "";
      this._logStatus = LogStatus.Logged;
    }
  }

  static async getToken() {
    await this.msalInstance.initialize();
    await this.msalInstance.handleRedirectPromise();
    const accounts = this.msalInstance.getAllAccounts();
    const silentRequest = {
      scopes: [".default"], // required scope
      account: accounts[0],
    };
    try {
      await this.msalInstance
        .acquireTokenSilent(silentRequest)
        .then((response) => {
          this.token = response.accessToken;
          this._logStatus = LogStatus.Logged;
          this._name = response.account?.name ?? "";
          this._id = response.account?.localAccountId ?? "";
          this._mail = response.account?.username ?? "";
        });
    } catch (e) {
      try {
        await this.msalInstance.loginRedirect();
      } catch (e) {
        console.error(e);
        this._logStatus = LogStatus.NotAuthorized;
      }
    }
  }
}
