<template>
    <div class="widget-container">
        <DxHtmlEditor v-model="model" >
            <DxToolbar>
                <DxItem name="undo" />
                <DxItem name="redo" />
                <DxItem :accepted-values="headerValues" name="header" />
                <DxItem name="bold" />
                <DxItem name="italic" />
                <DxItem name="strike" />
                <DxItem name="underline" />
                <DxItem name="link" />
            </DxToolbar>
        </DxHtmlEditor>
    </div>
</template>
<script setup lang="ts">
import { define } from "core-js/core/object";
import { DxHtmlEditor, DxToolbar, DxItem } from "devextreme-vue/html-editor";

const headerValues = [false, 1, 2, 3, 4, 5];

const model = defineModel();

</script>
<style>
.dx-htmleditor {
  background-color: white;
  height: calc(100vh - 140px);
  overflow: auto;
}

.dx-htmleditor-submit-element {
  display: none;
}

.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.value-content {
  margin-top: 20px;
  overflow: auto;
  height: 75%;
  width: 100%;
  white-space: pre-wrap;
}

.options {
    margin-top: 20px;
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    box-sizing: border-box;
    width: 100%;
}

.dx-htmleditor-content {
  min-height: 100px;
}

</style>