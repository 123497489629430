/* eslint-disable */

import urlJoin from "url-join";
import { appEnv, isDevelopment } from "./env";

const u3tServerEndpoints: Record<typeof process.env.VUE_APP_ENV, string> = {
  development: "https://localhost:44304/",
  stage: "",
  production: "",
};

/**
 * Represents Azure KPI provider server domain
 */
export function getU3tServerUrl(): string {
  return u3tServerEndpoints[appEnv];
}

/**
 * Represents server domain
 */
export function getSPServerUrl(explicitOrigin = false): string {
  if (explicitOrigin && isDevelopment) {
    return _spPageContextInfo.portalUrl;
  }

  return window.location.origin;
}

/**
 * Represents default sub-site of NO-CompetenceLibrary resolved as a relative path
 */
export function getSPCompetenceLibraryRelativeUrl() {
  return _spPageContextInfo.siteServerRelativeUrl;
}

/**
 * Represents default sub-site of NO-CompetenceLibrary/OGC-KPI
 */
export function getSPOgcKpiRelativeUrl() {
  return urlJoin(_spPageContextInfo.siteServerRelativeUrl, "PA-OGC-KPI");
}

/**
 * Represents default sub-site of NO-CompetenceLibrary
 */
export function getSPCompetenceLibraryUrl(explicitOrigin = false) {
  if (!explicitOrigin) return _spPageContextInfo.siteAbsoluteUrl;
  return urlJoin(
    getSPServerUrl(explicitOrigin),
    getSPCompetenceLibraryRelativeUrl()
  );
}

/**
 * Represents default sub-site of NO-CompetenceLibrary/PA-OGC-KPI
 */
export function getSPOgcKpiUrl(explicitOrigin = false) {
  return urlJoin(getSPServerUrl(explicitOrigin), getSPOgcKpiRelativeUrl());
}

/**
 * Return url profile picture on SharePoint site.
 * @param {string} email User e-mail.
 * @param {string} size Desire profile picture size: size=S (48×48 px) or size=M (72×72 px) or size=L (300×300 px). Default: size=M.
 */
export function getUserPhotoUrl(email: string, size: "S" | "M" | "L" = "M") {
  return urlJoin(
    getSPServerUrl(),
    `_layouts/15/userphoto.aspx?size=${size}&accountname=${email}`
  );
}

export function getDescriptionItemFormUrl(id?: number): string {
  const url = new URL(
    urlJoin(getSPOgcKpiUrl(true), "Lists/PageDescriptions/EditForm.aspx")
  );
  id && url.searchParams.set("ID", String(id));
  return url.toString();
}

export function getTeamViewLink(geid?: string) {
  const url = new URL(
    urlJoin(getSPCompetenceLibraryUrl(true), "SitePages/TeamView.aspx")
  );
  geid && url.searchParams.set("geid", geid);
  return url.toString();
}

/**
 *
 * @returns {string} Base URL for API requests
 */
export function getServerUrl(): string {
  const baseURL = process.env.VUE_APP_API_BASE_URL;

  if (!baseURL) {
    throw new Error("Base URL not defined in environment variables");
  }
  return baseURL;
}
