<script setup lang="ts">
import { useAttrs, computed } from "vue";

defineProps({
  icon: { type: String, required: true },
});

const attrs = useAttrs();
const tag = computed<"a" | "button">(() => {
  return attrs.href ? "a" : "button";
});
</script>

<template>
  <component
    :is="tag"
    class="btn top-bar-btn"
    v-bind="$attrs"
    v-on="$attrs"
  >
    <slot>
      <font-awesome-icon :icon="icon" />
    </slot>
  </component>
</template>

<style lang="scss">
$size: 40px;

.top-bar-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: $size;
  height: $size;
  padding: 0;
  color: #666 !important;
  font-size: 17px;
  transition: 0.3s;
  background: #f5f5f5;

  &:hover {
    color: #111 !important;
    background-color: #e6e6e6 !important;
  }

  &:focus {
    box-shadow: none !important;
    background-color: #e6e6e6 !important;
  }
}
</style>
