import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/Authorization/LoginView.vue";
import CreateView from "@/views/CreateView.vue";
import TeamStoryView from "@/views/TeamStoryView.vue";

import { isDevelopment } from "@/common/env";
import { Authorization } from "@/services/authenticate";
import EditView from "@/views/EditView.vue";
import DetailView from "@/views/DetailView.vue";

const routes: Array<RouteRecordRaw> = [
    {
      path: "",
      name: "TeamStory",
      props: true,
      component: TeamStoryView,
      children: [
        {
          path: "/:template",
          name: "Home",
          props: true,
          component: HomeView,
        },
        {
          path: "/:template/create",
          name: "Create",
          props: true,
          component: CreateView,
        },
        {
          path: "/:template/edit/:id",
          name: "Edit",
          props: true,
          component: EditView,
        },
        { 
          path: "/:template/detail/:id",
          name: "Detail",
          props: true,
          component: DetailView,
        }
      ],
    },
  {
    path: "/login",
    name: "Login",
    props: true,
    component: LoginView,
  },  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !Authorization.isLogged) {
    console.log("Not logged", to.fullPath?.toString());
    sessionStorage.setItem("log_to", to.fullPath?.toString() ?? "/");
    next({ name: "Login" });
  } else {
    next();
  }
});

export default router;
