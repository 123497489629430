<template>
  <router-view />
</template>

<script setup lang="ts">
</script>

<style lang="scss">
@import "assets/scss/personPhoto.scss";
@import "assets/scss/common.scss";

.toolbar {
  display: flex;
  align-items: center;
  width: 100%;
}

body {
  height: 100%;
  margin: 0;
  font-family: ABBVoice, Verdana, Arial, sans-serif;
  background-color: #e6e6e6 !important;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 30px 5px 0 5px;
}

.row.short-gutters {
  margin-right: -7px;
  margin-left: -7px;
}

.short-gutters>.col,
.short-gutters>[class*="col-"] {
  padding-right: 7px;
  padding-left: 7px;
}

.vertical-gutters>.col,
.vertical-gutters>[class*="col-"] {
  padding-top: 7px;
  padding-bottom: 7px;
}

.vertical-gutters-top>.col,
.vertical-gutters-top>[class*="col-"] {
  padding-bottom: 7px;
}

.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  width: 22px;
  height: 22px;
  font-size: 22px;
}
</style>
