<template>
    <Teleport to="body">
        <div class="description">
            <div class="description__content">
                <font-awesome-icon :icon="['fas', 'xmark']" class="description__content__header__close"
                    @click="close" />
                <div class="description__content__header">
                    <h2 class="description__content__header__title">Team Story</h2>
                </div>
                <div class="description__content__body">
                    <p>
                        <span style="color: black"><strong>​</strong></span><span
                            style="color: black"><strong>​</strong></span><span
                            style="color: black"><strong>​</strong></span><span
                            style="color: black"><strong>​</strong></span><span
                            style="color: black"><strong>​</strong></span><span
                            style="font-family: verdana; font-size: 11pt"><strong>Your project&nbsp;and team
                                effort&nbsp;is the heart of our customers and
                                our&nbsp;business</strong></span><br />
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt">U3t makes it is easy for anyone&nbsp;to
                            create or participate in&nbsp;a
                            team story.</span><br />
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt">Please feel free to add stories, assign a
                            topic, heading,&nbsp;short
                            descriptions, pictures and tags.</span><br />
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt"><b><b
                                    style="background-color: rgb(255, 255, 255)">Extended use of U3t Team
                                    stories?</b></b></span>
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt"><b>Example:</b>&nbsp;</span><span
                            style="font-family: verdana; font-size: 11pt">Anyone can participate in our story on how ABB
                            products, solutions and
                            services contribute to customers Energy Transition &amp;
                            Sustainability.&nbsp;</span><span style="font-family: verdana; font-size: 11pt">It is easy
                            and we are not looking for exact science. :-)</span><br />
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt"><b>Step 1</b></span><span
                            style="font-family: verdana; font-size: 11pt">:&nbsp; in U3t select a Team. Selecting a team
                            expose the related functions
                            in U3t.</span>
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt"><b>Step 2</b></span><span
                            style="font-family: verdana; font-size: 11pt">: From the context menu select the 'Team
                            story' function (It is the 'Heart
                            beat' icon)</span>
                    </p>
                    <p>
                        <span style="font-family: verdana; font-size: 11pt"><b>Step 3</b></span><span
                            style="font-family: verdana; font-size: 11pt">: Add one or more Team Story 'Information'
                            item(s) add the&nbsp;</span><span
                            style="font-family: verdana; font-size: 11pt"><b>Tag</b></span><span
                            style="font-family: verdana; font-size: 11pt"> "</span><span
                            style="font-family: verdana; font-size: 11pt"><b>Sustainability</b></span><span
                            style="font-family: verdana; font-size: 11pt">" </span><span
                            style="font-family: verdana; font-size: 11pt">and the body text with the format:</span><span
                            style="font-family: verdana; font-size: 11pt">
                            <br />(Tip copy the format required from this text :-)</span>
                    </p>
                    <div style="margin-top: 14px; margin-bottom: 14px">
                        <span style="font-family: verdana; font-size: 11pt">#ABB project's scope is: </span><span
                            style="font-family: verdana; font-size: 11pt">&nbsp;[Supporting Contributor] [Direct
                            Contributor]</span>
                        <br />
                        <div>
                            <span style="font-family: verdana; font-size: 11pt">
                                #Summary description: </span><span
                                style="font-family: verdana; font-size: 11pt">&nbsp;&lt;Write a short
                                description&gt;</span>
                            <br />
                        </div>
                        <div>
                            <span style="font-family: verdana; font-size: 11pt">#End User Project's total reduction is:
                            </span>
                            <span style="font-family: verdana; font-size: 11pt">
                                [Estimated][Verified]
                            </span>
                            <br />
                        </div>
                        <div>
                            <span style="font-family: verdana; font-size: 11pt">
                                #Climate gas reduction CO2 Equivalents Tones / Year: </span><span
                                style="font-family: verdana; font-size: 11pt">&lt;nnn&gt;</span>
                            <br />
                        </div>
                        <div>
                            <span style="font-family: verdana; font-size: 11pt">#Description and source of data:
                            </span>
                            <span style="font-family: verdana; font-size: 11pt">& lt;Write a short summary on background
                                for figure and source if
                                relevant & gt; </span><br />
                        </div>
                    </div>
                    <div style="margin-top: 14px; margin-bottom: 14px">
                        <span style="font-family: verdana; font-size: 11pt">The Heading of the Team story entry can be
                            any text you choose.
                        </span>
                        <br /><span style="font-family: verdana; font-size: 11pt">
                            This is an example of a Team story information item for Northern Lights:&
                            nbsp; </span><br /><img style="max-width: 100%" /><br />
                    </div>
                    <p>
                        <span style="font-size: 11pt; font-family: verdana"><b>The Team story entries you create are
                                automatically machine processed and
                                visible in U3t</b></span><span style="font-size: 11pt; font-family: verdana">
                            Sales &amp; Delivery References after each Tuesday update.&nbsp;</span><a
                            href="/sites/NO-CompetenceLibrary/PowerBI/SitePages/SalesReferences.aspx"
                            title="Link to Sales References"><span style="font-size: 11pt; font-family: verdana">Link to
                                Sales &amp; Delivery References</span></a>
                    </p>
                    <p><strong></strong></p>
                    <p style="background-color: rgb(255, 255, 255)">
                        Note: We will enhance the solution based on your feedback and development
                        plans​.<br />
                    </p>
                    <p></p>
                    <p><b>Good practices:</b></p>
                    <p>
                        <strong></strong><span
                            style="background-color: rgb(255, 255, 255); display: inline !important">Write short and to
                            the point. Add a picture.&nbsp;</span>
                    </p>
                    <p>
                        <span style="background-color: rgb(255, 255, 255); display: inline !important">Add tags. (It
                            might be useful to find and collect stories in your future of
                            U3t.)</span>
                    </p>
                    <p>
                        Do not add pictures where&nbsp;persons&nbsp;can be identified&nbsp;unless it
                        has been agreed with each person.<br />
                    </p>
                    <p><br /></p>
                </div>
            </div>
        </div>
    </Teleport>
</template>

<script setup lang="ts">
import { isDevelopment } from "@/common/env";
import { getDescriptionItemFormUrl } from "@/common/urls";
import { fetchPageDescription } from "@/services/pageDescription";
import { useUserStore } from "@/stores/user";
import { PageDescription } from "@/types/PageDescriptionListItem";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";

const emit = defineEmits(["close"]);

const pageName = "TeamStory";
const title = "Team Story";

// const userStore = useUserStore();
// const { isManager } = storeToRefs(userStore);

// const description = ref<PageDescription | null>(null);

// const getPageDescription = () => {
//     if (isDevelopment) {
//         description.value = { Id: 56, Content: "Unable to load description" } as PageDescription;
//     }
//     else {
//         fetchPageDescription(pageName).then((d) => (description.value = d));
//     }
// };

// const editFromLink = computed(() => {
//     return getDescriptionItemFormUrl(description.value?.Id);
// });

// onMounted(() => {
//     getPageDescription();
// });

function close() {
    emit("close");
}
</script>

<style scoped lang="scss">
.description {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
}

.description__content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 600px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
}

.description__content__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.description__content__header__title {
    font-size: 24px;
    font-weight: bold;
}

.description__content__header__close {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 30px;
}

.description__content__body {
    margin-bottom: 20px;
}

.description__content__body p {
    margin-bottom: 10px;
}
</style>
