import ApiConnector from "../apiConnector";

class AzureGraphDataFetcher {
  private readonly apiConnector: ApiConnector;

  constructor() {
    this.apiConnector = new ApiConnector("https://graph.microsoft.com/v1.0/");
  }
  public async getUserManager(id: string): Promise<any> {
    return await this.apiConnector.get<any>(`users/${id}/manager`);
  }

  public async getMyPhoto(): Promise<any> {
    return await this.apiConnector.getBlob<any>("me/photo/$value");
  }
}

export default AzureGraphDataFetcher;
