<template>
  <div class="main">
    <div class="row title">
      <h4>{{ state.teamName }}</h4>
      <router-link :to="{ name: 'Create' }">
        <button><font-awesome-icon icon="fa fa-plus" /> New</button>
      </router-link>
    </div>
  </div>
  <div class="row">
    <StoryList v-if="!teamStoryStore.getLoadingStatus" :stories="teamStoryStore.getTeamStories" />
    <LoadingDots v-else />
  </div>
</template>

<script setup lang="ts">
import StoryList from "@/components/TeamStory/StoryList.vue";
import { useTeamStoryStore } from "@/stores/teamStory";
import LoadingDots from "@/components/util/LoadingDots.vue";
import { onMounted, reactive } from "vue";
import { getQueryParamByName } from "@/common/util";
import router from "@/router";
import { useTeamStore } from "@/stores/team";

const teamStoryStore = useTeamStoryStore();
const teamStore = useTeamStore();

const props = defineProps<{
  template: string;
}>();

const state = reactive({
  teamName: "",
});

onMounted(async () => {
  await teamStoryStore.loadTeamStory(props.template);
  if (teamStoryStore.getTeamStories.length > 0) {
    if (!teamStore.getTeamName)
      await teamStore.fetchTeamInfo(teamStoryStore.getTeamStories[0].targetCode);
    if (teamStore.getTeamName) state.teamName = teamStore.getTeamName;
    else state.teamName = "Unknown";
  }
});

</script>

<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  height: 80%;
}

.title{
  margin-left: 5px;
  margin-right: 5px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.row-right {
  display: flex;
  align-items: right;
  justify-content: right;
}
</style>